/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "@emotion/styled"
import theme from "../../gatsby-plugin-theme-ui"
import BurgerMenuItem from "./BurgerMenuItem"

const Outer = styled.div<{
  isOpen: boolean
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: ${props => (props.isOpen ? "block" : "none")};
`

const Inner = styled.div`
  width: 70%;
  height: 100%;
  background: ${theme.colors.background.base};
`

type BurgerMenuProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  topics: {
    edges: {
      node: {
        title: string
        slug: string
        excerpt?: string
        topicCustomFields: {
          accentColor?: string
          accentColorLight?: string
        }
      }
    }[]
  }
}

const BurgerMenu: React.FC<BurgerMenuProps> = ({
  isOpen,
  setIsOpen,
  topics,
}) => {
  return (
    <Outer
      isOpen={isOpen}
      onClick={(e: React.MouseEvent): void => {
        if (e.target === e.currentTarget) {
          setIsOpen(false)
        }
      }}
    >
      <Inner>
        <nav>
          <ul sx={{ listStyle: "none", p: 0, m: 0 }}>
            <BurgerMenuItem
              title="Topics"
              hasSubmenu={true}
              submenuItems={topics?.edges.map(({ node }) => {
                return {
                  title: node.title,
                  link: { type: "internal", to: `/${node.slug}/` },
                }
              })}
            />
            <BurgerMenuItem
              title="Contribute"
              link={{ type: "internal", to: "/contribute/" }}
            />
            <BurgerMenuItem
              title="NeurIPS 2021"
              link={{ type: "internal", to: "/neurips21/" }}
            />
          </ul>
        </nav>
      </Inner>
    </Outer>
  )
}

export default BurgerMenu
