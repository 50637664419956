/** @jsx jsx */
import { Box, Flex, Grid, Container, Heading, Text, jsx } from "theme-ui"
import { StaticImage } from "gatsby-plugin-image"
import Copyright from "./Copyright"

const Footer = () => {
  return (
    <Box as="footer" sx={{ backgroundColor: "background.dark" }}>
      <Container py={9}>
        {/* <Flex mb={8} sx={{ minHeight: "150px" }}>
          <Box sx={{ maxWidth: "330px" }}>
            <Box mb={4}>
              <StaticImage
                src="../../images/logos/dcai-full.png"
                alt="Data-centric AI Logo"
                width={170}
              />
            </Box>
          </Box>
        </Flex> */}
        <Flex>
          <Copyright />
        </Flex>
      </Container>
    </Box>
  )
}

export default Footer
