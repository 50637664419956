import React from "react"
import Header from "./header"
import Footer from "./footer"
import Announcement from "./Announcement"

type LayoutProps = {
  announcementBanner?: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ announcementBanner, children }) => {
  return (
    <>
      {announcementBanner && <Announcement>{announcementBanner}</Announcement>}
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
