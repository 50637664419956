/** @jsx jsx */
import { jsx } from "theme-ui"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Logo = ({ isSticky }) => (
  <Link
    to="/"
    sx={{
      position: "relative",
      zIndex: 1,
      height: "100%",
      display: "flex",
      alignItems: "center",
      padding: isSticky ? "14px 0" : ["24px 0", null, "0"],
    }}
  >
    {isSticky ? (
      <StaticImage
        src="../../images/logos/dcai-initials.png"
        height={35}
        alt="Data-centric AI"
        loading="eager"
        style={{
          width: "auto",
          maxHeight: "100%",
          height: isSticky ? "100%" : "83px",
        }}
        imgStyle={{
          width: "auto",
        }}
      />
    ) : (
      <StaticImage
        src="../../images/logos/dcai-full.png"
        height={83}
        alt="Data-centric AI"
        loading="eager"
        style={{
          width: "auto",
          maxHeight: "100%",
        }}
        imgStyle={{
          width: "auto",
        }}
      />
    )}
  </Link>
)

export default Logo
