/** @jsx jsx */
import { useState, useEffect } from "react"
import { jsx, Container } from "theme-ui"
import { FiXCircle } from "react-icons/fi"

const Announcement = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    if (window.sessionStorage.getItem("announcement") === "closed") {
      setIsOpen(false)
    }
  }, [])

  useEffect(() => {
    if (!isOpen) {
      window.sessionStorage.setItem("announcement", "closed")
    }
  }, [isOpen])

  return isOpen ? (
    <aside
      sx={{
        backgroundColor: "tertiary.dark",
        padding: "8px 0",
        minHeight: "50px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div sx={{ display: "flex", alignItems: "center" }}>
          <New />
          <div
            sx={{
              fontSize: 2,
              "*": {
                color: "white",
              },
              "a:hover": {
                borderBottom: "1px solid white",
              },
            }}
          >
            {children}
          </div>
        </div>
        <Close onClick={() => setIsOpen(false)} />
      </Container>
    </aside>
  ) : null
}

const New = () => (
  <div
    sx={{
      backgroundColor: "white",
      color: "tertiary.dark",
      textTransform: "uppercase",
      fontSize: 0,
      fontWeight: "medium",
      letterSpacing: "caps",
      borderRadius: 2,
      padding: "2px 6px",
      mr: 2,
    }}
  >
    New
  </div>
)

const Close = props => (
  <button
    sx={{
      backgroundColor: "transparent",
      border: "none",
      p: 0,
      m: 0,
      color: "white",
      fontSize: "22px",
      lineHeight: 0,
      cursor: "pointer",
    }}
    {...props}
  >
    <FiXCircle />
  </button>
)

export default Announcement
