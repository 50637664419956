/** @jsx jsx */
import { css } from "@emotion/react"
import React, { useState } from "react"
import { jsx, Box } from "theme-ui"
import theme from "../../gatsby-plugin-theme-ui"
import BurgerMenu from "./BurgerMenu"

const burger = css`
  position: relative;
  width: 32px;
  height: 45px;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    position: relative;
    width: 32px;
    height: 20px;
  }

  span {
    position: absolute;
    cursor: pointer;
    height: 3px;
    width: 32px;
    background: ${theme.colors.text.dark};
    display: block;
    content: "";
    top: 0;
    transition: all 0.2s ease;

    &::before,
    &::after {
      cursor: pointer;
      height: 3px;
      width: 32px;
      background: ${theme.colors.text.dark};
      position: absolute;
      display: block;
      content: "";
    }

    &::after {
      transition: all 0.2s ease;
      top: 18px;
    }

    &::before {
      transition: all 0.2s ease;
      top: 9px;
    }
  }
`

const active = css`
  span {
    transform: rotate(45deg);
    top: 10px;
    background: ${theme.colors.text.dark};

    &::before {
      background-color: transparent;
    }

    &::after {
      background: ${theme.colors.text.dark};
      transform: rotate(90deg);
      top: 0;
    }
  }
`

const Burger = ({ topics }) => {
  const [isActive, setActive] = useState(false)

  return (
    <Box sx={{ display: ["block", null, null, "none"] }}>
      <button
        css={isActive ? [burger, active] : [burger]}
        onClick={() => setActive(!isActive)}
      >
        <div>
          <span />
        </div>
      </button>
      <BurgerMenu topics={topics} isOpen={isActive} setIsOpen={setActive} />
    </Box>
  )
}

export default Burger
