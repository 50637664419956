/** @jsx jsx */
import { jsx, Box, Flex } from "theme-ui"
import { connectSearchBox, PoweredBy } from "react-instantsearch-dom"
import { FiSearch } from "react-icons/fi"

const SearchBox = ({ currentRefinement, refine }) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Flex sx={{ alignItems: "center" }}>
        <input
          type="search"
          value={currentRefinement}
          onChange={event => refine(event.currentTarget.value)}
          sx={{
            backgroundColor: "background.base",
            border: "none",
            height: "60px",
            padding: "0 20px 0 60px",
            fontSize: "16px",
            borderRadius: 5,
            width: "100%",
            transition: "all 0.12s",
            // display: ["none", null, null, "block"],
            boxShadow: "0 0 0 2px rgba(0,0,0,0.1)",
            "&:focus": {
              outline: "none",
            },
          }}
          placeholder="Search"
        />
        <div
          sx={{
            position: "absolute",
            width: ["30px", null, null, "26px"],
            height: ["30px", null, null, "26px"],
            left: "20px",
            padding: "2px",
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
            svg: {
              width: "100%",
              height: "100%",
            },
            color: "text.base",
          }}
        >
          <FiSearch />
        </div>
        <div
          sx={{
            position: "absolute",
            right: "20px",
          }}
        >
          <PoweredBy />
        </div>
      </Flex>
    </Box>
  )
}

const CustomSearchBox = connectSearchBox(SearchBox)

export default CustomSearchBox
