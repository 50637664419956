/** @jsx jsx */
import { jsx, Heading, Text } from "theme-ui"
import { Link } from "gatsby"
import { Snippet, Highlight } from "react-instantsearch-dom"

const getSlug = url => {
  let arr = url.split("/")
  return arr[arr.length - 1] || arr[arr.length - 2]
}

const Hit = ({ hit }) => {
  let subDirectory = null
  if (hit.post_type === "post") {
    subDirectory = "blog"
  }

  return (
    <div
      sx={{
        mt: 3,
      }}
    >
      <Link
        to={
          subDirectory
            ? `/${subDirectory}/${getSlug(hit.permalink)}/`
            : `/${getSlug(hit.permalink)}/`
        }
        sx={{
          display: "block",
          transition: "all 0.2s",
          "&:hover": {
            backgroundColor: "background.dark",
            borderRadius: "2px",
            boxShadow: "0 0 0 10px #f6f8f8",
          },
        }}
      >
        <div
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div sx={{ width: "100%" }}>
            <h4
              sx={{
                m: 0,
                fontSize: 3,
              }}
            >
              <Highlight attribute="post_title" hit={hit} tagName="mark" />
            </h4>
            <p
              sx={{
                m: "8px 0 0",
                color: "text.light2x",
              }}
            >
              <Snippet attribute="content" hit={hit} tagName="mark" />
            </p>
          </div>
          {hit.images?.thumbnail && (
            <div
              sx={{
                width: "100%",
                maxWidth: "150px",
                marginLeft: 4,
                borderRadius: 4,
                overflow: "hidden",
                display: ["none", null, "block"],
              }}
            >
              <img
                src={hit.images?.thumbnail?.url}
                alt={hit.post_title}
                sx={{
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          )}
        </div>
      </Link>
    </div>
  )
}

export default Hit
