/** @jsx jsx */
import { Link } from "gatsby"
import { Box, Text, jsx } from "theme-ui"
import parser from "html-react-parser"
import theme from "../../gatsby-plugin-theme-ui"

type TopicCardProps = {
  topic: {
    title: string
    excerpt?: string
    slug: string
    topicCustomFields: {
      accentColor?: string
      accentColorLight?: string
    }
  }
}

const TopicCard: React.FC<TopicCardProps> = ({
  topic: { title, excerpt, slug, topicCustomFields },
}) => (
  <Link
    to={`/${slug}/`}
    sx={{
      height: "100%",
    }}
  >
    <Box
      p={5}
      sx={{
        backgroundColor: "background.dark",
        borderRadius: 4,
        // borderLeft: `4px solid ${
        //   topicCustomFields?.accentColor || theme.colors.secondary.base
        // }`,
        // borderLeft: `4px solid ${theme.colors.secondary.base}`,
        height: "100%",
        "&:hover": {
          // backgroundColor:
          //   topicCustomFields?.accentColorLight || "background.dark2x",
          backgroundColor: "background.dark2x",
        },
      }}
    >
      <Text
        as="p"
        variant="default"
        sx={{
          fontWeight: "medium",
          color: "text.dark",
          fontSize: "20px",
          textAlign: "center",
        }}
      >
        {title}
      </Text>
      {/* {excerpt && (
        <Text
          as="div"
          mt={2}
          color="text.light"
          sx={{
            fontSize: 2,
            p: { m: 0 },
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
          }}
        >
          {parser(excerpt)}
        </Text>
      )} */}
    </Box>
  </Link>
)

export default TopicCard
