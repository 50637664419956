/** @jsx jsx */
import { jsx } from "theme-ui"
import { useEffect, useState, useRef } from "react"
import { Box, Container, Flex } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"

import Logo from "./Logo"
import Nav from "./Nav"
import Search from "./Search"
import Burger from "./Burger"

const Header = () => {
  const { topics } = useStaticQuery(graphql`
    query HeaderQuery {
      topics: allWpTopic(sort: { fields: menuOrder, order: ASC }) {
        edges {
          node {
            title
            slug
            excerpt
            topicCustomFields {
              accentColor
              accentColorLight
            }
          }
        }
      }
    }
  `)
  const [isSticky, setIsSticky] = useState(false)
  const [navMenuIsOpen, setNavMenuIsOpen] = useState(false) // This state is used to set color on header when nav menu is open
  const ref = useRef()

  useEffect(() => {
    const cachedRef = ref.current,
      observer = new IntersectionObserver(
        ([e]) => setIsSticky(e.intersectionRatio < 1),
        { threshold: [1] }
      )

    observer.observe(cachedRef)

    return function () {
      observer.unobserve(cachedRef)
    }
  }, [])

  return (
    <Box
      as="header"
      ref={ref}
      sx={{
        position: "sticky",
        height: ["120px", null, "140px"],
        top: "-1px",
        backgroundColor: navMenuIsOpen ? "background.base" : "background.dark",
        zIndex: 100,
        transition: "height 0.12s",
        borderBottom: "none",
        borderColor: "border.base",
        "&.sticky": {
          height: "64px",
          backgroundColor: "background.base",
          boxShadow: "navigation",
        },
      }}
      className={isSticky ? `sticky` : ``}
    >
      <Container sx={{ height: "100%" }}>
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Logo isSticky={isSticky} />
          <Nav
            topics={topics}
            isSticky={isSticky}
            setNavMenuIsOpen={setNavMenuIsOpen}
          />
          <Flex>
            <Search isSticky={isSticky} />
            <Burger topics={topics} />
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

export default Header
