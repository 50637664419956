/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useState } from "react"
import { Link } from "gatsby"
import { BsChevronDown } from "react-icons/bs"

type BurgerMenuItemProps = {
  title: string
  link?: {
    to: string
    type: "internal" | "external"
  }
  hasSubmenu?: boolean
  submenuItems?: BurgerMenuItemProps[]
}

const titleStyle = {
  fontSize: 2,
  fontWeight: "medium",
  padding: "16px 32px",
  fontFamily: "body",
  display: "flex",
}

const BurgerMenuItem: React.FC<BurgerMenuItemProps> = ({
  title,
  link,
  hasSubmenu,
  submenuItems,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <li
      sx={{
        width: "100%",
        backgroundColor: isOpen ? "background.dark" : "inherit",
      }}
    >
      {hasSubmenu ? (
        <>
          <button
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "transparent",
              width: "100%",
              border: "none",
              cursor: "pointer",

              ...titleStyle,
            }}
            onClick={() => setIsOpen(!isOpen)}
          >
            <span>{title}</span>
            <span
              sx={{
                transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
              }}
            >
              <BsChevronDown strokeWidth="1" />
            </span>
          </button>
          <ul
            sx={{
              listStyle: "none",
              display: isOpen ? "block" : "none",
              pl: 0,
            }}
          >
            {submenuItems?.map((item, index) => (
              <BurgerMenuItem key={index} {...item} />
            ))}
          </ul>
        </>
      ) : link?.type === "internal" ? (
        <Link to={link.to} sx={titleStyle}>
          {title}
        </Link>
      ) : (
        <a href={link.to} sx={titleStyle}>
          {title}
        </a>
      )}
    </li>
  )
}

export default BurgerMenuItem
