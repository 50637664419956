/** @jsx jsx */
import { jsx, Box, Container, Heading } from "theme-ui"
import algoliasearch from "algoliasearch/lite"
import { InstantSearch, Hits, Index } from "react-instantsearch-dom"
import CustomSearchBox from "./SearchBox"
import Hit from "./Hit"
import { BsX } from "react-icons/bs"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const SearchWindow = ({ active, setActive }) => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.4)",
        display: active ? "block" : "none",
        zIndex: 10,
      }}
      onClick={(e: React.MouseEvent): void => {
        if (e.target === e.currentTarget) {
          setActive(false)
        }
      }}
    >
      <Container
        variant="narrow"
        py={"80px"}
        sx={{
          position: "relative",
        }}
      >
        <InstantSearch searchClient={searchClient} indexName="wp_posts_post">
          <CustomSearchBox autoFocus />
          <Box
            sx={{
              position: "relative",
              backgroundColor: "background.base",
              width: "100%",
              mt: 2,
              borderRadius: 5,
              boxShadow: "0 0 0 2px rgba(0,0,0,0.1)",
              p: 4,
              maxHeight: "700px",
              overflowY: "scroll",
            }}
          >
            <Index indexName="wp_posts_post">
              <Heading variant="uppercase" sx={{ color: "text.light3x" }}>
                Posts
              </Heading>
              <Hits hitComponent={Hit} />
            </Index>
            <Index indexName="wp_posts_topic">
              <Heading
                variant="uppercase"
                sx={{ mt: 5, color: "text.light3x" }}
              >
                Topics
              </Heading>
              <Hits hitComponent={Hit} />
            </Index>
          </Box>
        </InstantSearch>
      </Container>
      <button
        sx={{
          position: "fixed",
          top: "20px",
          right: "20px",
          width: "45px",
          height: "45px",
          backgroundColor: "white",
          borderRadius: "100%",
          border: "none",
          padding: 0,
          zIndex: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "27px",
          cursor: "pointer",
          color: "text.light",

          "&:hover": {
            backgroundColor: "background.dark",
          },
        }}
        onClick={() => setActive(false)}
      >
        <BsX />
      </button>
    </Box>
  )
}

export default SearchWindow
