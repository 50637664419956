/** @jsx jsx */
import { useState, useEffect } from "react"
import { jsx, Flex } from "theme-ui"
import { FiSearch } from "react-icons/fi"
import SearchWindow from "../search"

type SearchBarProps = {
  isSticky: boolean
}

const Search: React.FC<SearchBarProps> = ({ isSticky }) => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    let searchShortcut = event => {
      if (event.code === "Slash") {
        setActive(true)
      }
    }
    document.addEventListener("keydown", searchShortcut, false)
    return () => {
      document.removeEventListener("keydown", searchShortcut, false)
    }
  }, [])

  return (
    <Flex sx={{ position: "relative", alignItems: "center" }}>
      <input
        type="search"
        placeholder='Site search... (Press "/" to focus)'
        sx={{
          backgroundColor: "background.dark2x",
          border: "none",
          height: isSticky ? "42px" : "50px",
          padding: "0 16px",
          fontSize: 1,
          borderRadius: 5,
          minWidth: "284px",
          transition: "height 0.12s",
          display: ["none", null, null, "block"],
        }}
        disabled
      />
      <button
        sx={{
          position: ["relative", null, null, "absolute"],
          right: 0,
          left: 0,
          padding: "0 16px 0 0",
          backgroundColor: "transparent",
          border: "none",
          cursor: "pointer",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          svg: {
            width: ["28px", null, null, "23px"],
            height: ["28px", null, null, "23px"],
          },
        }}
        onClick={() => setActive(!active)}
      >
        <FiSearch />
      </button>
      <SearchWindow active={active} setActive={setActive} />
    </Flex>
  )
}

export default Search
