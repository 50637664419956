import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { Text, Box } from "theme-ui"
import { BsChevronDown } from "react-icons/bs"

type NavItemProps = {
  title: string
  hasSubmenu?: boolean
  link?: {
    type: "internal" | "external"
    url: string
    openInNewTab?: boolean
  }
  setNavMenuIsOpen?: (isOpen: boolean) => void
}

const NavItem: React.FC<NavItemProps> = ({
  title,
  hasSubmenu,
  link,
  children,
  setNavMenuIsOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (isOpen && hasSubmenu) {
      setNavMenuIsOpen(true)
    } else {
      setNavMenuIsOpen(false)
    }
  }, [isOpen, hasSubmenu])

  if (hasSubmenu) {
    return (
      <Li>
        <Title
          aria-haspopup="menu"
          aria-expanded={false}
          onClick={() => setIsOpen(!isOpen)}
          isActive={isOpen}
        >
          <TitleText>{title}</TitleText>
          <Box sx={{ width: "16px", height: "16px", ml: "6px" }}>
            <BsChevronDown strokeWidth="1" />
          </Box>
        </Title>
        <Menu isOpen={isOpen}>{children}</Menu>
      </Li>
    )
  } else {
    return (
      <Li>
        {link.type === "internal" ? (
          <Link to={link.url}>
            <Title as="span">
              <TitleText>{title}</TitleText>
            </Title>
          </Link>
        ) : (
          <a href={link.url} target={link?.openInNewTab ? "_blank" : "_self"}>
            <Title as="span">
              <TitleText>{title}</TitleText>
            </Title>
          </a>
        )}
      </Li>
    )
  }
}

const Title = styled.button<{
  isActive?: boolean
}>`
  color: ${props =>
    props.isActive
      ? props.theme.colors.primary.base
      : props.theme.colors.text.dark};
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
  }
`

const TitleText = ({ children }) => (
  <Text
    sx={{
      fontWeight: "medium",
      fontSize: 3,
      letterSpacing: "links",
    }}
  >
    {children}
  </Text>
)

const Li = styled.li`
  margin: 0 14px;
  height: 94px;

  &:hover {
    ${Title} {
      color: ${props => props.theme.colors.primary.base};
    }
  }
`

const Menu = styled(Box)<{ isOpen: boolean }>`
  position: absolute;
  z-index: 500;
  margin: 0;
  display: ${props => (props.isOpen ? "block" : "none")};
  width: 100%;
  left: 0;
  top: 100%;
  margin-top: -1px;
  background-color: ${props => props.theme.colors.background.base};
  box-shadow: ${props => props.theme.shadows.navDropdownMenu};
`

export default NavItem
