/** @jsx jsx */
import { jsx } from "theme-ui"
import { Flex, Container, Grid } from "theme-ui"
import NavItem from "./NavItem"
import TopicCard from "./TopicCard"

type NavProps = {
  isSticky: boolean
  topics: {
    edges: {
      node: {
        title: string
        slug: string
        excerpt?: string
        topicCustomFields: {
          accentColor?: string
          accentColorLight?: string
        }
      }
    }[]
  }
  setNavMenuIsOpen: (isOpen: boolean) => void
}

const Nav: React.FC<NavProps> = ({ isSticky, topics, setNavMenuIsOpen }) => {
  return (
    <Flex
      as="nav"
      aria-label="Main menu"
      sx={{
        position: "absolute",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        left: 0,
        top: 0,
        height: isSticky ? "64px" : "140px",
        transition: "all 0.12s",
        display: ["none", null, null, "flex"],
      }}
    >
      <Flex
        as="ul"
        sx={{ listStyle: "none", alignItems: "center", p: 0, m: 0 }}
      >
        <NavItem
          title="Topics"
          hasSubmenu={true}
          setNavMenuIsOpen={setNavMenuIsOpen}
        >
          <Container>
            <Grid
              sx={{
                gridTemplateColumns: "repeat(3, 1fr)",
                gridGap: 7,
                pt: 7,
                pb: 8,
              }}
            >
              {topics.edges.map(({ node }) => (
                <TopicCard key={`nav_item_${node.slug}`} topic={node} />
              ))}
            </Grid>
          </Container>
        </NavItem>
        <NavItem
          title="Contribute"
          link={{
            type: "internal",
            url: "/contribute/",
          }}
          setNavMenuIsOpen={setNavMenuIsOpen}
        />
        <NavItem
          title="NeurIPS 2021"
          link={{
            type: "external",
            url: "/neurips21/",
            openInNewTab: true,
          }}
          setNavMenuIsOpen={setNavMenuIsOpen}
        />
      </Flex>
    </Flex>
  )
}

export default Nav
